<template>
<div class="information">
<!--  顶部导航-->
  <div class="dh">
    <div class="navigation">
      <div class="ico" @click="go()">
        <img class="icon" src="../assets/img15.png">
        <p class="designation">华钛信息</p>
      </div>
      <div class="selectivity">
        <div class="alternative">
          <div class="xzx1 xzxz1">
            <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
            <div class="nav1 nav">
              <div class="navxx">
                <span>行业方案</span>
                <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzData" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img188.png">
              </div>
            </div>
          </div>
          <div class="xzx2 xzxz1">
            <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
            <div class="nav2 nav">
              <div class="navxx">
                <span>自主产品</span>
                <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img189.png">
              </div>
            </div>
          </div>
          <div class="xzx3 xzxz1">
            <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
            <div class="nav3 nav">
              <div class="navxx">
                <span>服务范围</span>
                <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img190.png">
              </div>
            </div>
          </div>
          <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
            <span class="xzxz" @click="din(item)">{{item.cname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第一层-->
  <div class="top">
    <div class="fl">
      <div class="bj">
        <div class="zss">
          <div class="xz">
            <span class="xz1">华钛信息</span>
            <span class="xz2">来自IT各个领域的资深专家所组成的优秀团队。</span>
            <span class="xz3">我们是中国优质的企业信息服务提供商，拥有专业的技术和实施团队，主要向客户提供数字运营服务、软件开发、云计算、软件外包等各类信息化产品和服务。</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第二层-->
  <div class="er">
    <div class="spreadhead">
      <div class="subhead">
        <div class="moit">
          <img class="img3" src="../assets/img3.png">
          <span>选择华钛的四大理由</span>
          <img class="img4" src="../assets/img4.png">
        </div>
      </div>
    </div>
    <div class="jh">
      <div class="jh1">
        <ul>
          <li @click="curss=0" >
            <div :class="{ active: curss == 0 }">
              <span :class="{ active: curss == 0 }">高效稳定</span>
              <span :class="{ active: curss == 0 }">深厚的基础架构,自研的操作系统，多年技术锤炼和海量服务经验，为客户提供高性能、易运维、智能化、弹性化的云服务</span>
            </div>
          </li>
          <li @click="curss=1">
            <div :class="{ active: curss == 1 }">
              <span :class="{ active: curss == 1 }">开放生态</span>
              <span :class="{ active: curss == 1 }">完整的合作伙伴生态体系，与产业链上下游通力合作，提供优质的服务和丰富的产品满足您全方位的业务需求。</span>
            </div>
          </li>
          <li @click="curss=2">
            <div :class="{ active: curss == 2 }">
              <span :class="{ active: curss == 2 }">丰富场景</span>
              <span :class="{ active: curss == 2 }">从基础设施到行业应用领域，提供完善的产品体系，为各种业务场景提供全栈解决方案，助力业务腾飞。</span>
            </div>
          </li>
          <li @click="curss=3">
            <div :class="{ active: curss == 3 }">
              <span :class="{ active: curss == 3 }">服务保障</span>
              <span :class="{ active: curss == 3 }">7*24小时高效服务保障，多元化的服务支持，提供专业的上云前、中、后全流程技术服务，让您的云上之旅更安心。</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
<!--  第三层-->
  <div class="san">
    <div class="spreadhead">
      <div class="subhead">
        <div class="moit">
          <img class="img3" src="../assets/img3.png">
          <span>华钛里程碑</span>
          <img class="img4" src="../assets/img4.png">
        </div>
      </div>
    </div>
    <div class="lc">
      <div class="lc1">
        <div class="dianes">
          <img src="../assets/img163.png">
        </div>
        <div class="diann"></div>
        <div class="lc2">
          <div class="lc3">
            <div class="cx">
              <span>2020年2月</span>
              <span>企业成立</span>
            </div>
            <div class="cs">
              <span>2020年8月</span>
              <span>伴随华钛信息的发展，累积了丰富的经验，华钛信息初具雏形。</span>
            </div>
          </div>
          <div class="lc3">
            <div class="cx">
              <span>2021年2月</span>
              <span>华钛信息凭借业内精英团队，以1年时间完成了同<br>等规模公司3年的业绩水准。</span>
            </div>
            <div class="cs">
              <span>2021年6月</span>
              <span>研发团队自主研发供应商管理系统，成功助力企业完成供应商管理数字化转型。</span>
            </div>
          </div>
          <div class="lc3">
            <div class="cx">
              <span>2021年12月</span>
              <span>成为2021年度甄云科技年度最佳合作伙伴。</span>
            </div>
            <div class="cs">
              <span>2022年3月</span>
              <span>企业进一步达成战略转型，由企业定制开发逐步转向自主研发产品，企业成本管理分析平台产品成功上线。</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第四层-->
  <div class="four-c">
    <div class="spreadhead">
      <div class="subhead">
        <div class="moit">
          <img class="img3" src="../assets/img3.png">
          <span>权威认证</span>
          <img class="img4" src="../assets/img4.png">
        </div>
      </div>
    </div>
    <div class="certificate">
      <div class="css3" v-for="(item, index) in css3" :key="index">
        <div class="nb">
          <el-image
              style=""
              :src="item.im1"
              :preview-src-list="item.im2">
          </el-image>
          <div class="details">
            <span>{{ item.top }}</span>
            <span>{{ item.bottom }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="fg">
      <span class="cutt1"></span>
      <span class="renz">获得众多合规认证</span>
      <span class="cutt2"></span>
    </div>

    <div class="zs">
      <div class="zs1">
        <div class="zs2">
          <div class="zs3" v-for="(item, index) in ZsData" :key="index">
            <el-image
                style=""
                :src="item.zmg"
                :preview-src-list="item.srcList">
            </el-image>
            <span>{{item.zsg}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  底层-->
  <div class="ground-floor">
    <div class="fbox">
      <div class="fbox1">
        <div class="fbox2">
          <div class="lianxi">
            <div class="fico">
              <img class="ficon" src="../assets/img2.png">
              <p class="fdesignation">华钛信息</p>
            </div>
            <div class="contway">
              <div class="special">
                <span>联系电话：</span>
                <span>021-55962658</span>
              </div>
              <div class="special">
                <span>商务合作：</span>
                <span>business@huataish.com</span>
              </div>
            </div>
          </div>
          <div class="cut-line"></div>
          <div class="explain">
            <div class="explain1">
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">行业方案</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                      <span @click="din(item)">{{ item.lei }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">自主产品</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                      <span @click="din(item)">{{ item.lei1 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">服务范围</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                      <span @click="din(item)">{{ item.lei2 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="cl()">客户案例</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="hz()">合作伙伴</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="xx()">华钛信息</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                      <span @click="din(item)">{{ item.lei4 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cut-line1"></div>
        <div class="safety">
          <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "Home.vue",
  data() {
    return {
      cur: 0,
      curss:0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopData: [
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [
      ],
      foolDataess: [
      ],
      ZsData:[
        {
          zmg:require('../assets/img176.png'),
          zsg:"IOS27001证书",
          srcList:[require("../assets/img194.jpg")]
        },
        {
          zmg:require('../assets/img177.png'),
          zsg:"IOS20000证书",
          srcList:[require("../assets/img195.jpg")]
        },
        {
          zmg:require('../assets/img178.png'),
          zsg:"IOS9001证书",
          srcList:[require("../assets/img196.png")]
        },
        {
          zmg:require('../assets/img179.png'),
          zsg:"大数据统计分析证书",
          srcList:[require("../assets/img204.png")]
        },
        {
          zmg:require('../assets/img180.png'),
          zsg:"工作引擎交互证书",
          srcList:[require("../assets/img206.png")]
        },
        {
          zmg:require('../assets/img181.png'),
          zsg:"网页开户系统证书",
          srcList:[require("../assets/img205.png")]
        },
      ],
      css3:[
        {

          im1: require('../assets/img221.jpg'),
          im2: [require('../assets/img220.jpg')],
          top:"CMMI3企业认证",
          bottom:"凭借多年的软件开发经验，华钛信息获得由CMMI认的软件开发资质，包含定制化开发，产品化方案，平台研发，自主研发等多个产品领域",
        },
        {
          im2:[require('../assets/img218.jpg')],
          im1:require('../assets/img219.jpg'),

          top:"SRM软件产品证书",
          bottom: "华钛信息自主研发供应商管理系统已和多家，企业达成合作关系，在此基础上得到了协会认可，华钛信息在采购管理完全具备交付合格软件产品的能力"
        }
      ]

    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }
}
</script>
<style scoped >
/*第一层*/
.top{
  align-items: flex-end;
  display: flex;
  height: 526px;
  justify-content: center;
  position: relative;
  width: 100%;
}
.top .fl{
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.top .fl .bj{
  background-image: url("../assets/img21.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
/*第二层*/
.er{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /*height: 444px;*/
  background-color: #f2f3f6;
  background-image: url("../assets/img161.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.er .jh{
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.er .jh .jh1{
  width: 1180px;
  height: 298px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.er .jh .jh1 ul{
  width: 1180px;
  height: 298px;
  display: flex;
  justify-content: space-between;
}
.er .jh .jh1 ul li {
  display: flex;
  width: 280px;
  height: 298px;
  flex-direction: column;
  overflow: hidden;
}
.er .jh .jh1 ul li div{
  display: flex;
  width: 280px;
  height: 298px;
  flex-direction: column;
}
.er .jh .jh1 ul li div span:first-child{
  width: 100px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 33px;
  margin-top: 42px;
  margin-left: 29px;
}
.er .jh .jh1 ul li div span:first-child.active{
  color: #FFFFFF;
}
.er .jh .jh1 ul li div span:last-child{
  width: 198px;
  height: 130px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666F8F;
  line-height: 26px;
  margin-top: 34px;
  margin-left: 29px;
}
.er .jh .jh1 ul li div span:last-child.active{
  color: #FFFFFF;
}
.er .jh .jh1 ul li div.active{
background-image: url("../assets/img162.png");
  background-size: cover;
  background-repeat: no-repeat;
}
/*第三层*/
.san{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 746px;
  background: #f9faff;
}
.san .lc{
  flex: 1;
}
.san .lc .lc1{
  width: 1185px;
  height: 100%;
  margin: 0 auto;
}
.san .lc .lc1 .diann{
  z-index: 100;
  width: 1185px;
  height: 13px;
  /*background-image: url("../assets/img163.png");*/
  /*background-size: cover;*/
  /*background-repeat: no-repeat;*/
  position: relative;
  top: 251px;
}
.san .lc .lc1 .lc2{
  z-index: 150;
  width: 1215px;
  height: 600px;
  display: flex;
  justify-content: space-between;
}
.san .lc .lc1 .lc3{
  width: 480px;
  height: 510px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.san .lc .lc1 .lc3 .cx{
  display: flex;
  flex-direction: column;
  height: 255px;
  background: url(../assets/img164.png) -20px -20px no-repeat;
  width: 380px;
  z-index: 1000;
  position: relative;
  top: 18px;
  left: -5px;
  box-shadow: -8px -11px 16px 0px #DEE3ED;
}
.san .lc .lc1 .lc3 .cx span:first-child{
  width: 103px;
  height: 30px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(18, 75, 255, 1);
  font-size: 22px;
  letter-spacing: -1.100000023841858px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  margin-left: 12px;
  margin-top: 15px;
}
.san .lc .lc1 .lc3 .cx span:last-child{
  width: 306px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(32, 43, 78, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 19px;
  margin-left: 12px;
}
.san .lc .lc1 .lc3 .cs{
  display: flex;
  flex-direction: column;
  height: 255px;
  background: url(../assets/img165.png) -20px -19px no-repeat;
  width: 380px;
  z-index: 1000;
  position: relative;
  top: -5px;
  box-shadow: 5px 8px 16px 0px #DEE3ED;
}
.san .lc .lc1 .lc3 .cs span:first-child{
  width: 103px;
  height: 30px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(18, 75, 255, 1);
  font-size: 22px;
  letter-spacing: -1.100000023841858px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  margin-left: 12px;
  margin-top: 73px;
}
.san .lc .lc1 .lc3 .cs span:last-child{
  width: 306px;
  height: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(32, 43, 78, 1);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  line-height: 24px;
  text-align: left;
  margin-top: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12px;
}
/*第四层*/
.four-c{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 670px;
}
.four-c .certificate{
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  width: 1180px;
}
.four-c .css3{
  width: 580px;
  height: 184px;
  background: linear-gradient(180deg, #F7FAFF 0%, #FFFFFF 100%);
  box-shadow: 0px 0px 20px 0px #E6EAF3;
  border-radius: 4px;
  border: 2px solid #FFFFFF;
}
.css3 .nb{
  padding: 32px 39px 39px 20px;
  display: flex;
  justify-content: space-between;
}
.css3 .details{
  width: 342px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.details span:first-child{
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 28px;
}
.details span:last-child{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 24px;
}
.four-c .cutt1{
  display: inline-block;
  width: 508px;
  height: 2px;
  background: linear-gradient(270deg, rgba(221, 223, 236, 0.34) 0%, rgba(212, 214, 231, 0.55) 34%, rgba(216, 216, 216, 0) 100%);
}
.four-c .cutt2{
  display: inline-block;
  width: 508px;
  height: 2px;
  background: linear-gradient(33deg, rgba(221, 223, 236, 0.34) 0%, rgba(212, 214, 231, 0.55) 34%, rgba(216, 216, 216, 0) 100%);
}
.fg{
  width: 1180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.four-c .renz{
  width: 128px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #BCBFD2;
  line-height: 22px;
  margin: 20px auto;
}
.four-c .amg141{
  display: block;
  width: 1220px;
  height: 248px;
  margin: 0 auto;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  /*margin-bottom: 48px;*/
  /*background: red;*/
}

.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}

.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 415px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}

.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}

.spreadhead .subhead .moit span {
  display: block;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}

.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}

 .zs{
  flex: 1;
}
 .zs .zs1{
  display: flex;
  justify-content: space-between;
  width: 1180px;
  height: 196px;
  margin: 0 auto;
   background: linear-gradient(180deg, #F7FAFF 0%, #FFFFFF 100%);
   box-shadow: 0px 0px 20px 0px #E6EAF3;
   border-radius: 4px;
   border: 2px solid #FFFFFF;
}
 .zs .zs1 .zs2{
  padding: 24px 48px;
  width: 1110px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
 .zs .zs1 .zs2 .zs3{
  display: flex;
  flex-direction: column;
  height: 150px;
}
 .zs .zs1 .zs2 .zs3 img{
  display: block;
  width: 82px;
  height: 117px;
  margin: 0 auto;
}
 .zs .zs1 .zs2 .zs3 span{
  text-align: center;
  font-size: 13px;
  margin-top: 6px;
}
 .dianes{
   position: relative;
   top: 284px;
 }
 .xz{
   display: flex;
   flex-direction: column;
   width: 747px;
   height: 300px;
   position: relative;
   top:140px;
   /*left: 86px;*/
 }
 .xz .xz1{
   width: 168px;
   height: 59px;
   font-size: 42px;
   font-family: PingFangSC-Medium, PingFang SC;
   font-weight: 500;
   color: #333333;
   line-height: 59px;
 }
 .xz .xz2{
   width: 626px;
   height: 84px;
   font-size: 30px;
   font-family: PingFangSC-Regular, PingFang SC;
   font-weight: 400;
   color: #333333;
   line-height: 42px;
 }
 .xz .xz3{
   width: 747px;
   height: 102px;
   font-size: 18px;
   font-family: PingFangSC-Regular, PingFang SC;
   font-weight: 400;
   color: #333333;
   line-height: 34px;
 }
</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}
.zs .el-image__inner{
  width: 82px;
  display: block;
  height: 117px;
  margin: auto;
}
.el-image-viewer__wrapper{
  z-index: 10000000000;
}
</style>